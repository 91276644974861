import clsx from "clsx";
import React from "react";

export const Button = (props) => {
  const { className, loading, disabled, children, variant = "primary", ...rest } = props;

  return (
    <button {...rest} disabled={disabled || loading} className={clsx(className, "py-1 px-2.5 h-[30px] tracking-tight overflow-hidden rounded group inline-flex justify-center transition items-center text-xs disabled:hover:bg-gray-50 hover:bg-gray-300", { "bg-gradient-to-b from-blue-500 to-blue-600 text-white": variant === "primary", "bg-slate-200 hover:bg-slate-300": variant === "secondary" })}>
      {loading && (
        <svg aria-hidden="true" className="w-[17px] h-[17px] flex-none animate-spin" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.2" d="M3.06445 15.0214C3.06445 8.41779 8.41779 3.06445 15.0214 3.06445C21.6251 3.06445 26.9784 8.41779 26.9784 15.0214C26.9784 21.6251 21.6251 26.9784 15.0214 26.9784C8.41779 26.9784 3.06445 21.6251 3.06445 15.0214Z" stroke="#bfdbfe" strokeWidth="2.5" />
          <path d="M3.06445 15.0214C3.06445 8.41779 8.41779 3.06445 15.0214 3.06445" stroke="url(#paint0_linear_205_123)" strokeWidth="2.5" strokeLinecap="round" />
          <defs>
            <linearGradient id="paint0_linear_205_123" x1="6.41929" y1="3.70961" x2="7.53757" y2="13.215" gradientUnits="userSpaceOnUse">
              <stop stopColor="#eff6ff" />
              <stop offset="1" stopColor="#eff6ff" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      )}
      {!loading && (
        <>
          {children}
          {variant === "primary" && (
            <svg className="ml-2.5 -mr-1 stroke-white stroke-[1px]" fill="none" width="12" height="12" viewBox="0 0 10 10" aria-hidden="true">
              <path className="transition opacity-0 group-hover:opacity-100" d="M0 5h7"></path>
              <path className="transition group-hover:translate-x-[2.5px]" d="M1 1l4 4-4 4"></path>
            </svg>
          )}
        </>
      )}
    </button>
  );
};
