import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Assignments } from "./Assignments";
import { useSolver } from "./lib/solver";
import { Solution } from "./Solution";
import { Wizard } from "./Wizard";
import { Login } from "./Login";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { About } from "./About";

export const App = () => {
  const navigate = useNavigate();
  const [assignments, setAssignments] = useState();

  useEffect(() => {
    fetchAssignments();
  }, []);

  const fetchAssignments = async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/assignments`, {
      credentials: "include",
      headers: {
        "X-CSRF-TOKEN": Cookies.get("csrf_access_token"),
      },
    });
    if (res.ok) {
      const dict = await res.json();
      const assignments = Object.keys(dict).map((id) => ({ id, ...dict[id] }));
      setAssignments(assignments);
    } else {
      if (res.status === 401) {
        navigate("/login");
      }
    }
  };

  const onMessage = (data) => {
    fetchAssignments();
  };

  const { isConnected } = useSolver(onMessage);

  const logout = async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-TOKEN": Cookies.get("csrf_access_token"),
      },
    });

    if (res.ok) {
      navigate("/login");
    } else {
      console.log("Logout was not successful");
    }
  };

  return (
    <div>

      {/* Mobile Message */}
      <p className="flex items-center justify-center p-2 text-xs text-white bg-slate-900 sm:hidden">
        This app is not optimized for mobile devices. Please use a desktop computer.
      </p>

      {/* Top Level Navigation */}
      <nav className="flex items-center h-10 border-b">
        <div className="flex items-center justify-between w-full max-w-6xl p-4 mx-auto">
          <a href="/">
            <span className="text-[13px] tracking-tight">LBZ Student Assignment Optimization</span>
          </a>
          <div className="flex items-center space-x-4">
            <span className={clsx("text-xs inline-flex items-center", { "text-green-600": isConnected, "text-red-600": !isConnected })}>
              <span aria-hidden="true" className={clsx("w-1.5 h-1.5 mr-1.5 rounded-full flex-shrink-0", { "bg-green-600": isConnected, "bg-red-600 motion-safe:animate-ping": !isConnected })}></span>
              {isConnected ? "Server Connected" : "Server Offline"}
            </span>
            <button onClick={logout} className="text-xs">
              Logout
            </button>
          </div>
        </div>
      </nav>
      
      <Routes>
        <Route path="/" element={<Assignments assignments={assignments} onUpdate={fetchAssignments} />} />
        <Route path="/new" element={<Wizard />} />
        <Route path="/solution/:id" element={<Solution />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  );
};
