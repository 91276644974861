import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SettingsStep } from "./components/settingsStep";
import { UploadStep } from "./components/uploadStep";
import { ValidationStep } from "./components/validationStep";
import Cookies from "js-cookie";
import clsx from "clsx";

export const Wizard = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [problem, setProblem] = useState(null);
  const [error, setError] = useState(null);

  const minSize = useRef();
  const maxSize = useRef();

  const handleUpload = (problem) => {
    setProblem(problem);
    setStep((prev) => prev + 1);
  };

  const startSolver = async (settings) => {
    if (!problem.id) {
      setError("There is no assignment id. Please reach out to a contact person.");
      return;
    }

    // Start the solver
    const res = await fetch(`${process.env.REACT_APP_API_URL}/solve`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": Cookies.get("csrf_access_token"),
      },
      body: JSON.stringify({
        id: problem.id,
        settings,
      }),
      credentials: "include",
    });
    if (res.ok) {
      navigate("/");
    } else if (res.status === 401) {
      navigate("/login");
    } else {
      setError("Starting the solver was not successful. Please try again later or reach out to a contact person.");
    }
  };

  // Steps for Breadcrumbs
  const steps = [
    {
      name: "Upload",
      number: 0,
    },
    {
      name: "Validation",
      number: 1,
    },
    {
      name: "Settings",
      number: 2,
    },
  ].slice(0, step + 1);

  return (
    <main className="max-w-4xl p-4 mx-auto my-20 bg-white rounded-lg shadow-lg shadow-slate-900/10 ring-1 ring-slate-500/5">
      <div className="px-4 py-2">
        {/* TODO: Render all steps and only display one. This way, the state is not lost when navigating back. */}
        {/* <Breadcrumbs steps={steps} selectStep={(step) => setStep(step)} selectedStep={step} /> */}
        {step === 0 && <UploadStep onNext={handleUpload} />}
        {step === 1 && (
          <ValidationStep
            body={problem}
            onNext={(min, max) => {
              minSize.current = min;
              maxSize.current = max;
              setStep(2);
            }}
            onPrev={() => setStep(0)}
          />
        )}
        {step === 2 && <SettingsStep minSize={minSize?.current} maxSize={maxSize?.current} onNext={startSolver} />}
        {error && <p className="text-red-600 my-2 text-[13px]">{error}</p>}
      </div>
    </main>
  );
};

const Breadcrumbs = ({ steps, selectStep, selectedStep }) => {
  return (
    <nav className="flex text-[13px] pb-4" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-3">
        {steps.map((step, index) => (
          <li key={step.name}>
            <div className="flex items-center">
              <button onClick={() => selectStep(step.number)} className={clsx("mr-4 hover:text-slate-700", { "text-slate-700 font-medium": selectedStep === step.number, "text-slate-500": selectedStep !== step.number })} disabled={selectedStep === step.number}>
                {step.name}
              </button>
              {index !== steps.length - 1 && (
                <svg aria-hidden="true" className="flex-shrink-0 w-4 h-4 text-slate-400" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
