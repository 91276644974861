import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import clsx from "clsx";

export const Tooltip = ({ children, text }) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>
          <button>{children}</button>
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content sideOffset={4} className={clsx("radix-side-top:animate-slide-down-fade", "radix-side-right:animate-slide-left-fade", "radix-side-bottom:animate-slide-up-fade", "radix-side-left:animate-slide-right-fade", "inline-flex items-center rounded-md px-4 py-2.5 shadow-md", "bg-slate-900")}>
          <TooltipPrimitive.Arrow className="text-slate-900 fill-current" />
          <span className="block text-xs leading-none text-slate-50">{text}</span>
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
