import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// map to order days in the timetable
const weekMap = {
  "so": 0,
  "mo": 1,
  "di": 2,
  "mi": 3,
  "do": 4,
  "fr": 5,
  "sa": 6,
};

export const Solution = () => {
  const navigate = useNavigate();
  // Selected course for the detail overlay
  const [selectedCourse, setSelectedCourse] = useState();
  // Search for the student list
  const [search, setSearch] = useState("");
  const [data, setData] = useState();
  const { id } = useParams();

  useEffect(() => {
    const loadSolution = async () => {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/solution/${id}`, {
        credentials: "include",
        headers: {
          "X-CSRF-TOKEN": Cookies.get("csrf_access_token"),
        },
      });
      if (res.ok) {
        const data = await res.json();
        setData(data);
      } else if (res.status === 401) {
        navigate("/login");
      }
    };

    if (id) loadSolution();
  }, [id, navigate]);

  const courses = data?.assignment?.courses || [];

  // Construct a list of day and timeslots and the groups that takes place in them
  const group = (courses) => {
    const result = {};
    courses.forEach((course) => {
      course.groups.forEach((group) => {
        const key = `${group.day} ${group.timeslot}`;
        if (!result.hasOwnProperty(key)) {
          result[key] = [];
        }
        result[key].push({ ...group, name: course.name + ": " + group.name });
      });
    });
    return result;
  };

  const grouped = group(courses);

  // Round function to match the Python implementation
  const round = (number) => Math.round((number + Number.EPSILON) * 10) / 10;

  if (!data) return null;

  // Day, Timeslots found in the data
  const slots = [
    ...new Set(
      courses.flatMap((course) =>
        course.groups.reduce((acc, group) => {
          const key = `${group.day} ${group.timeslot}`;
          return [...acc, key];
        }, [])
      )
    ),
  ].sort((a, b) => {
    const [dayA] = a?.toLowerCase().split(" ");
    const [dayB] = b?.toLowerCase().split(" ");

    // check if both days are in the weekmap
    if (weekMap.hasOwnProperty(dayA) && weekMap.hasOwnProperty(dayB)) {
      return weekMap[dayA] - weekMap[dayB];
    }

    return dayA.localeCompare(dayB);
  })

  // Get the index for the timeslot, e.g., timeMap["08:30"] = 0
  const timeMap = slots.reduce((prev, curr, index) => ({ ...prev, [curr]: index }), {});

  // Count the number of homogenous groups
  // -----------------------------------
  const dsszGroups = courses.find((course) => course.name.includes("DSSZ"))?.groups;
  const bwsGroups = courses.find((course) => course.name.includes("BWS"))?.groups;
  // Count the number of homogenous dssz groups.
  const homDsszGroups = dsszGroups
    .map((group) => {
      const language = group.students.map((student) => student.language).reduce((a, b) => a + b, 0);
      const education = group.students.map((student) => student.education).reduce((a, b) => a + b, 0);
      const isHomogenous = (language === 0 || language === group.students.length) && (education === 0 || education === group.students.length);
      return isHomogenous;
    })
    .reduce((a, b) => a + b, 0);

  // Count the number of homogenous dssz groups.
  const homBwsGroups = bwsGroups
    .map((group) => {
      const education = group.students.map((student) => student.education).reduce((a, b) => a + b, 0);
      const isHomogenous = education === 0 || education === group.students.length;
      return isHomogenous;
    })
    .reduce((a, b) => a + b, 0);

  return (
    <div className="text-gray-900 bg-white">
      <main className="pt-12">
        <div className="px-4 mx-auto max-w-7xl">
          <Link to="/" className="text-[13px] text-slate-600 inline-flex items-center group">
            <svg className="w-5 h-5 transition-transform group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M10.25 6.75L4.75 12L10.25 17.25"></path>
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19.25 12H5"></path>
            </svg>
            Back
          </Link>
          <h1 className="text-2xl font-semibold tracking-tight text-gray-700">Assignment Solution</h1>
          <span>{data?.settings?.name}</span>

          {/* Stats */}
          <dl className="grid grid-cols-5 gap-6 mt-10 text-sm tabular-nums">
            {data.settings["minimizeGaps"] && (
              <div className="p-3 border-gray-100 rounded-md">
                <dt className="text-xs text-gray-500">Number of gaps in the schedule</dt>
                <dd className="text-lg font-medium">{round(data?.assignment["gaps"])}</dd>
              </div>
            )}
            <div className="p-3 border-gray-100 rounded-md">
              <dt className="text-xs text-gray-500">Free timeslots in front of biology</dt>
              <dd className="text-lg font-medium">{round(data?.assignment["free_time_before_bio"] * 100)}%</dd>
            </div>
            <div className="p-3 border-gray-100 rounded-md">
              <dt className="text-xs text-gray-500">Homogenous DSSZ groups</dt>
              <dd className="text-lg font-medium">
                {homDsszGroups} of {dsszGroups.length}
              </dd>
            </div>
            <div className="p-3 border-gray-100 rounded-md">
              <dt className="text-xs text-gray-500">Homogenous BWS groups</dt>
              <dd className="text-lg font-medium">
                {homBwsGroups} of {bwsGroups.length}
              </dd>
            </div>
            <div className="p-3 border-gray-100 rounded-md">
              <dt className="flex items-center justify-between text-xs text-gray-500">Objective Function</dt>
              <dd className="flex flex-col text-lg font-medium border-collapse border-spacing-0">
                <span>{round(data?.assignment["objective"].value)}</span>
              </dd>
            </div>
          </dl>

          {/* Extra Information, Objective Function and Settings */}
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="inline-flex items-center text-sm font-medium">
                  Extra Information
                  <svg className={`${open ? "rotate-180 transform" : ""} w-6 h-6`} fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15.25 10.75L12 14.25L8.75 10.75"></path>
                  </svg>
                </Disclosure.Button>
                <Disclosure.Panel className="p-4 mt-2 border-2 border-dashed rounded border-slate-200">
                  <h2 className="text-sm">Parts of the objective function</h2>
                  <table cellSpacing={0} cellPadding={0} className="inline-block mt-2 text-xs">
                    <tr>
                      <td className="text-gray-500 border-b">DSSZ Language</td>
                      <td className="border-b pl-3.5 text-gray-500">DSSZ Education</td>
                      <td className="border-b pl-3.5 text-gray-500">BWS</td>
                      <td className="border-b pl-3.5 text-gray-500">Gaps</td>
                      <td className="border-b pl-3.5 text-gray-500">Biology</td>
                      <td className="border-b pl-3.5 text-gray-500">Mixed</td>
                    </tr>
                    <tr>
                      <td className="pt-1">{round(data?.assignment["objective"].dssz_lang)}</td>
                      <td className="pt-1 pl-3.5">{round(data?.assignment["objective"].dssz_educ)}</td>
                      <td className="pl-3.5 pt-1">{round(data?.assignment["objective"].bws)}</td>
                      <td className="pl-3.5 pt-1">{round(data?.assignment["objective"].gaps)}</td>
                      <td className="pl-3.5 pt-1">{round(data?.assignment["objective"].biology)}</td>
                      <td className="pl-3.5 pt-1">{round(data?.assignment["objective"].mix)}</td>
                    </tr>
                  </table>
                  <h2 className="mt-6 text-sm">Settings used for the assignment</h2>
                  {/* Settings as JSON */}
                  <pre className="mt-2 text-xs text-gray-900">{JSON.stringify(data.settings, null, 2)}</pre>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          {/* <Timetable /> */}
          <div className="relative mt-6">
            <div
              className="flex flex-auto overflow-x-scroll">
              <div style={{ width: "300%" }} className="flex flex-none max-w-none">
                <div className="sticky left-0 z-10 flex-none bg-white w-14 ring-1 ring-gray-100" />
                <div className="grid flex-auto grid-cols-1 grid-rows-1">
                  {/* Horizontal lines */}
                  <div className="grid col-start-1 col-end-2 row-start-1 divide-y divide-gray-100" style={{ gridTemplateRows: `repeat(${slots.length * 2}, minmax(3.5rem, 1fr))` }}>
                    <div className="row-end-1 h-7"></div>
                    {slots.map((slot) => (
                      <React.Fragment key={slot}>
                        <div>
                          <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-4 text-slate-600">{slot.replaceAll("_", ":")}</div>
                        </div>
                        <div />
                      </React.Fragment>
                    ))}
                  </div>
                  {/* Vertical lines */}
                  <div className="w-full col-start-1 col-end-2 grid-rows-1 row-start-1 divide-x divide-gray-100 sm:grid" style={{ gridTemplateColumns: `repeat(14, minmax(0, 1fr))` }}>
                    <div className="col-start-1 row-span-full" />
                    <div className="col-start-2 row-span-full" />
                    <div className="col-start-3 row-span-full" />
                    <div className="col-start-4 row-span-full" />
                    <div className="col-start-5 row-span-full" />
                    <div className="col-start-6 row-span-full" />
                    <div className="col-start-7 row-span-full" />
                    <div className="col-start-8 row-span-full" />
                    <div className="col-start-9 row-span-full" />
                    <div className="col-start-10 row-span-full" />
                    <div className="col-start-11 row-span-full" />
                    <div className="col-start-12 row-span-full" />
                    <div className="col-start-13 row-span-full" />
                    <div className="row-span-full" style={{ gridColumnStart: 14 }} />
                    <div className="w-8 row-span-full" style={{ gridColumnStart: 15 }} />
                  </div>
                  {/* Events */}
                  <ol className="grid col-start-1 col-end-2 row-start-1 sm:pr-8" style={{ gridTemplateColumns: `repeat(14, minmax(0, 1fr))`, gridTemplateRows: `1.75rem repeat(${slots.length * 2 * 24}, minmax(0, 1fr)) auto` }}>
                    {Object.keys(grouped).map((timeslot) => {
                      let count = 0;
                      return grouped[timeslot]
                        .sort((a, b) => {
                          if (a.name.includes("DSSZ") || a.name.includes("BWS")) return -1;

                          if (b.name.includes("DSSZ") || b.name.includes("BWS")) return 1;

                          if (a.name < b.name) return -1;
                          if (a.name > b.name) return 1;
                          return 0;
                        })
                        .map((group) => {
                          count++;
                          const language = group.students.map((student) => student.language).reduce((a, b) => a + b, 0);
                          const education = group.students.map((student) => student.education).reduce((a, b) => a + b, 0);
                          const isHomogenous = (language === 0 || language === group.students.length) && (education === 0 || education === group.students.length);
                          const homLang = language === 0 || language === group.students.length;
                          const homEduc = education === 0 || education === group.students.length;
                          const langScore = homLang ? 0 : 1;
                          const educScore = homEduc ? 0 : 1;
                          const homScore = group.name.includes("DSSZ") ? 2 - langScore - educScore : group.name.includes("BWS") ? 2 - educScore * 2 : 0;

                          const showHomogeneity = group.name.includes("DSSZ") || group.name.includes("BWS");
                          return (
                            <li key={group.name} className="relative hidden mt-px sm:flex" style={{ gridColumnStart: count, gridRow: `${2 + timeMap[timeslot] * 48} / span 48` }}>
                              {selectedCourse !== group.name && (
                                <a role="button" onClick={() => setSelectedCourse(group.name)} className={`${group.students.filter((s) => (search.length > 2 ? s.name.toString().toLowerCase().includes(search) : false)).length > 0 ? "ring-blue-500 ring-2" : "ring-gray-200/75 ring-1"} group bg-gray-50 absolute inset-2 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5 hover:bg-gray-100`}>
                                  <div className="flex items-start justify-between flex-grow">
                                    <p className="font-semibold text-gray-700">{group.name}</p>
                                    {showHomogeneity && <p className={`px-2 text-white ${clsx({ "bg-green-500": homScore === 2, "bg-orange-500": homScore === 1, "bg-red-500": homScore === 0 })} font-medium rounded-xl`}>{homScore === 2 ? "100%" : homScore === 0 ? "0%" : "50%"}</p>}
                                  </div>
                                  {!showHomogeneity && <span className="text-xs text-slate-600">{group?.students?.length} students</span>}
                                  {showHomogeneity && (
                                    <>
                                      <p className="text-xs text-slate-600">
                                        {language} of {group.students.length} students study a language
                                      </p>
                                      <p className="text-xs text-slate-600">
                                      {education} GyGe &mdash; {group.students.length - education} BK
                                      </p>
                                    </>
                                  )}
                                </a>
                              )}
                              {selectedCourse === group.name && (
                                <div className="group absolute left-0 top-0 w-96 flex flex-col ring-1 z-40 shadow-2xl ring-gray-100 overflow-y-auto rounded-lg bg-white py-3.5 px-4 text-xs leading-5">
                                  <div className="flex items-center justify-between">
                                    <p className="font-semibold text-gray-700">{group.name}</p>
                                    <button onClick={() => setSelectedCourse("")}>
                                      <svg width="20" height="20" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17.25 6.75L6.75 17.25"></path>
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6.75 6.75L17.25 17.25"></path>
                                      </svg>
                                    </button>
                                  </div>
                                  {showHomogeneity && (
                                    <>
                                      <p className="text-xs text-slate-600">
                                        {language} of {group.students.length} students study a language
                                      </p>
                                      <p className="text-xs text-slate-600">
                                      {education} GyGe &mdash; {group.students.length - education} BK
                                      </p>
                                    </>
                                  )}
                                  <table className="mt-2 text-xs">
                                    <thead>
                                      <tr>
                                        <td className="px-1">Mat. Nr.</td>
                                        <td className="px-1">Subject A</td>
                                        <td className="px-1">Subject B</td>
                                        <td className="px-1">Lang</td>
                                        <td className="px-1">Educ</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {group.students.map((student) => {
                                        const courses = student.courses.filter((c) => c.toLowerCase() !== "bws" && c.toLowerCase() !== "dssz");
                                        return (
                                          <tr key={student.name} className="mt-2">
                                            <td className="px-1 text-gray-700">{student.name}</td>
                                            <td className="text-gray-700 px-1 text-left max-w-[6rem] truncate">{courses[0]}</td>
                                            <td className="text-gray-700 px-1 text-left max-w-[6rem] truncate">{courses[1]}</td>
                                            <td className="px-1 text-gray-700">{student.language == 1 ? "yes" : "no"}</td>
                                            <td className="px-1 text-gray-700">{student.education == 1 ? "GyGe" : "BK"}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </li>
                          );
                        });
                    })}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* List of students */}
        <div className="mt-12 text-xs text-gray-500 pb-6 max-w-[100rem] px-4 mx-auto">
          <div>
            <label className="sr-only" htmlFor="search">
              Search for students
            </label>
            <input type="text" id="search" name="search" placeholder="Search Mat. Nr. ..." value={search} onChange={(e) => setSearch(e.target.value)} className="rounded px-4 py-1.5 border border-gray-200" />
          </div>

          <table className="w-full mt-2">
            <thead>
              <tr className="text-gray-900">
                <td className="px-3 py-2">Mat. Nr.</td>
                {slots
                  .sort((a, b) => a.localeCompare(b))
                  .map((timeslot) => (
                    <td key={timeslot} className="px-3 py-2">
                      {timeslot.replaceAll("_", ":")}
                    </td>
                  ))}
              </tr>
            </thead>
            <tbody className="">
              {data?.assignment.students &&
                data?.assignment.students
                  .filter((student) => student.toString().toLowerCase().includes(search.toLowerCase()))
                  .map((student) => {
                    const student_groups = data?.assignment.courses.reduce((acc, course) => {
                      for (const group of course.groups) {
                        if (group.students.some((s) => s.name === student)) {
                          return [...acc, { name: course.name, group, timeslot: `${group.day} ${group.timeslot}` }];
                        }
                      }
                      return acc;
                    }, []);
                    return (
                      <tr key={student} className="">
                        <td className="px-3 py-1 border-b">{student}</td>
                        {slots.map((timeslot) => {
                          const c = student_groups.filter((course) => course.timeslot === timeslot);
                          if (c.length === 0) return <td key={student + timeslot} className="px-3 py-1 border-b"></td>;
                          const [course] = c;
                          return (
                            <td key={student + timeslot} className="px-3 py-1 border-b max-w-[10rem] truncate">
                              {course?.name}-{course?.group.name}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
};
