import { Menu, Transition } from "@headlessui/react";
import { format, formatDuration, intervalToDuration } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import Cookies from "js-cookie";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "./components/button";
import { Tooltip } from "./components/tooltip";

export const Assignments = ({ assignments, onUpdate }) => {
  const navigate = useNavigate();

  const create = () => {
    navigate("/new");
  };

  const getDetails = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/details/${id}`, {
      credentials: "include",
      headers: {
        "X-CSRF-TOKEN": Cookies.get("csrf_access_token"),
      },
    });
    if (res.ok) {
      const details = await res.text();
      console.warn("Error Details", details);
      alert(details);
    } else if (res.status === 401) {
      navigate("/login");
    }
  };

  const stopAssignment = async (id) => {
    // TODO: POST
    const res = await fetch(`${process.env.REACT_APP_API_URL}/stop/${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "X-CSRF-TOKEN": Cookies.get("csrf_access_token"),
      },
    });
    if (res.ok) {
      alert("OK");
    } else {
      alert("NOT OK");
    }
  };

  const deleteAssignment = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/delete/${id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-TOKEN": Cookies.get("csrf_access_token"),
      },
    });
    if (res.ok) {
      onUpdate();
    } else if (res.status === 401) {
      navigate("/login");
    }
  };

  return (
    <div className="relative flex items-center justify-center w-full max-w-5xl p-4 mx-auto">
      <div className="w-full py-20">

        {/* Header Row */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-lg font-medium leading-tight tracking-tight">Assignments</h1>
            <p className="text-sm text-slate-600">The past and recent assignments.</p>
          </div>
          <Button onClick={create}>Create Assignment</Button>
        </div>

        <div className="mt-6 border divide-y rounded-lg divide-slate-200 border-slate-200">
          {assignments && (
            <AnimatePresence initial={false}>
              {assignments
                .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
                .map((assignment) => (
                  <motion.div key={assignment.id} initial={{ height: 0, opacity: 0 }} animate={{ height: "auto", opacity: 1 }} exit={{ height: 0, opacity: 0 }} transition={{ opacity: { duration: 0.2 } }} className="flex items-center justify-between text-sm">
                    <div className="flex items-center m-4 space-x-2">
                      <div className="flex flex-col">
                        <span className="font-medium">{assignment.name}</span>
                        <span className="text-xs text-gray-500">Created at {format(new Date(assignment.created_at), "dd.MM.yyyy HH:mm")}</span>
                        {assignment.started_at && assignment.finished_at && <span className="text-xs text-gray-500">Finished after {formatDuration(intervalToDuration({ start: new Date(assignment.started_at), end: new Date(assignment.finished_at) }))}</span>}
                      </div>
                    </div>
                    <div>
                      {assignment.status === "PENDING" && (
                        <div className="flex items-center space-x-2 text-xs">
                          {/* <button onClick={() => stopAssignment(assignment.id)} className="px-3 inline-flex items-center py-1.5 rounded-full border border-slate-300 text-slate-700 hover:bg-slate-100 transition">
                            Stop
                          </button> */}
                          <span className="inline-flex items-center px-4 py-2 text-xs font-medium text-blue-700 rounded-full">
                            <div className="relative flex items-center justify-center w-2 h-2 mr-2 -ml-1">
                              <span aria-hidden="true" className="absolute flex-shrink-0 w-2 h-2 bg-blue-700 rounded-full motion-safe:animate-ping"></span>
                              <span aria-hidden="true" className="absolute flex-shrink-0 w-1.5 h-1.5 rounded-full bg-blue-700"></span>
                            </div>
                            Running
                          </span>
                        </div>
                      )}

                      {assignment.status === "FINISHED" && (
                        <div className="flex items-center space-x-2 text-xs">
                          <div className="space-x-1">
                            <Link to={`/solution/${assignment.id}`} className="px-3 inline-flex items-center py-1.5 rounded-full border border-slate-300 text-slate-700 hover:bg-slate-100 transition">
                              View
                            </Link>
                            <a href={`${process.env.REACT_APP_API_URL}/export/${assignment.id}`} target="_blank" className="px-3 inline-flex items-center py-1.5 rounded-full border border-slate-300 text-slate-700 hover:bg-slate-100 transition">
                              Download
                            </a>
                            <DeleteDialog onDelete={() => deleteAssignment(assignment.id)} title={`Do you really want to delete the assignment "${assignment.name}"?`}>
                              <Menu.Button className="px-3 inline-flex items-center py-1.5 rounded-full border border-slate-300 text-slate-700 hover:bg-red-600 hover:text-white hover:border-red-600 transition">Delete</Menu.Button>
                            </DeleteDialog>
                          </div>
                          <span className="inline-flex items-center px-4 py-2 text-xs font-medium rounded-full text-emerald-700">
                            <div className="relative flex items-center justify-center w-2 h-2 mr-2 -ml-1">
                              <span aria-hidden="true" className="absolute flex-shrink-0 w-1.5 h-1.5 rounded-full bg-emerald-700"></span>
                            </div>
                            Finished
                          </span>
                        </div>
                      )}

                      {assignment.status === "QUEUED" && (
                        <div className="flex items-center space-x-2 text-xs">
                          <span className="inline-flex items-center px-4 py-2 text-xs font-medium rounded-full text-slate-500">
                            <div className="relative flex items-center justify-center w-2 h-2 mr-2 -ml-1">
                              <span aria-hidden="true" className="absolute flex-shrink-0 w-1.5 h-1.5 rounded-full bg-slate-500"></span>
                            </div>
                            Queued
                          </span>
                        </div>
                      )}

                      {assignment.status === "INFEASIBLE" && (
                        <div className="flex items-center space-x-2 text-xs">
                          <DeleteDialog onDelete={() => deleteAssignment(assignment.id)} title={`Do you really want to delete the assignment "${assignment.name}"?`}>
                            <Menu.Button className="px-3 inline-flex items-center py-1.5 rounded-full border border-slate-300 text-slate-700 hover:bg-red-600 hover:text-white hover:border-red-600 transition">Delete</Menu.Button>
                          </DeleteDialog>
                          <span className="inline-flex items-center px-4 py-2 text-xs font-medium text-red-700 rounded-full">
                            <div className="relative flex items-center justify-center w-2 h-2 mr-2 -ml-1">
                              <span aria-hidden="true" className="absolute flex-shrink-0 w-1.5 h-1.5 rounded-full bg-red-700"></span>
                            </div>
                            <Tooltip text="There is no solution for the given input.">No solution</Tooltip>
                          </span>
                        </div>
                      )}

                      {assignment.status === "TIMELIMIT" && (
                        <div className="flex items-center space-x-2 text-xs">
                          <DeleteDialog onDelete={() => deleteAssignment(assignment.id)} title={`Do you really want to delete the assignment "${assignment.name}"?`}>
                            <Menu.Button className="px-3 inline-flex items-center py-1.5 rounded-full border border-slate-300 text-slate-700 hover:bg-red-600 hover:text-white hover:border-red-600 transition">Delete</Menu.Button>
                          </DeleteDialog>
                          <span className="inline-flex items-center px-4 py-2 text-xs font-medium text-red-700 rounded-full">
                            <div className="relative flex items-center justify-center w-2 h-2 mr-2 -ml-1">
                              <span aria-hidden="true" className="absolute flex-shrink-0 w-1.5 h-1.5 rounded-full bg-red-700"></span>
                            </div>
                            <Tooltip text="The calculation exceeded the time limit. If you want the time limit to be increased, talk to a contact person.">Time limit</Tooltip>
                          </span>
                        </div>
                      )}

                      {assignment.status === "WARNING" && (
                        <div className="flex items-center space-x-2 text-xs">
                          <div className="space-x-1">
                            <DeleteDialog onDelete={() => deleteAssignment(assignment.id)} title={`Do you really want to delete the assignment "${assignment.name}"?`}>
                              <Menu.Button className="px-3 inline-flex items-center py-1.5 rounded-full border border-slate-300 text-slate-700 hover:bg-red-600 hover:text-white hover:border-red-600 transition">Delete</Menu.Button>
                            </DeleteDialog>
                            <button onClick={() => getDetails(assignment.id)} target="_blank" className="px-3 inline-flex items-center py-1.5 rounded-full border border-slate-300 text-slate-700 hover:bg-slate-100 transition">
                              Details
                            </button>
                          </div>
                          <span className="inline-flex items-center px-4 py-2 text-xs font-medium text-red-700 rounded-full">
                            <div className="relative flex items-center justify-center w-2 h-2 mr-2 -ml-1">
                              <span aria-hidden="true" className="absolute flex-shrink-0 w-1.5 h-1.5 rounded-full bg-red-700"></span>
                            </div>
                            Failed
                          </span>
                        </div>
                      )}
                    </div>
                  </motion.div>
                ))}
            </AnimatePresence>
          )}

          {/* Dialog in case no assignment has been created yet */}
          {assignments && assignments.length === 0 && (
            <div className="flex flex-col items-center justify-center w-full h-32 text-sm text-center text-slate-600">
              <div className="m-2">
                <p className="flex items-center justify-center text-sm">Currently, there are no assignments.</p>
                <p>
                  <button onClick={create} className="inline-flex p-1 -m-1 text-blue-600">
                    Create
                  </button>{" "}
                  your first assignment to start your student assignment optimization.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const DeleteDialog = (props) => {
  return (
    <Menu as="div" className="relative inline-flex">
      <>
        {props.children}
        <Transition as={React.Fragment} enter="transition duration-200 ease-out origin-top-right " enterFrom="transform scale-[0.15] opacity-0" enterTo="transform scale-100 opacity-100" leave="transition duration-150 ease-out" leaveFrom="transform scale-100 opacity-100" leaveTo="transform scale-95 opacity-0">
          <Menu.Items className="absolute z-50 text-[13px] top-full right-0 w-52 mt-1 -mr-0.5 bg-white shadow-lg rounded-md py-1 ring-1 ring-slate-900 ring-opacity-5 overflow-auto focus:outline-none" as="ul">
            <div className="px-4 py-2 space-y-2">
              <h3 className="">{props.title}</h3>
              <div className="flex items-center space-x-2">
                <Menu.Item onClick={props.onDelete} as="button" className="w-full">
                  {({ active }) => <span className={`${active ? "bg-red-700" : "bg-red-600"} text-white flex items-center transition-colors justify-center rounded-md w-full select-none py-1.5 px-3`}>Delete</span>}
                </Menu.Item>
                <Menu.Item onClick={props.onCancel} as="button" className="w-full">
                  {({ active }) => <span className={`${active ? "bg-slate-200" : "bg-slate-100"} flex items-center justify-center transition-colors rounded-md w-full select-none py-1.5 px-3`}>Cancel</span>}
                </Menu.Item>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </>
    </Menu>
  );
};
