import React, { useState } from "react";
import { Button } from "./button";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export const UploadStep = ({ onNext }) => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState();
  const [groupingFactor, setGroupingFactor] = useState(5);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const upload = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Prepare the request body
    var data = new FormData();
    data.append("file", selectedFile);
    data.append("groupingFactor", groupingFactor);
    try {
      // Use Promise.allSettled to have at least 750ms delay. Otherwise we get a weird flicker because of the loading spinner
      const [res] = await Promise.allSettled([
        fetch(`${process.env.REACT_APP_API_URL}/upload`, {
          method: "POST",
          body: data,
          credentials: "include",
          headers: {
            "X-CSRF-TOKEN": Cookies.get("csrf_access_token"),
          },
        }),
        new Promise((resolve) => setTimeout(resolve, 900)),
      ]);

      // Check if the request was successful
      if (res?.value?.ok) {
        const body = await res.value?.json();
        onNext(body);
      } else if (res?.value?.status === 401) {
        navigate("/login");
      } else {
        const body = await res.value?.json();
        setError(body.message || "An unexpected error occured. Please try again later or reach out to a contact person.");
      }
    } catch (error) {
      setError("An unexpected error occured. Please try again later or reach out to a contact person.");
    }
    setLoading(false);
  };
  return (
    <form onSubmit={upload} className="">
      <h1 className="tracking-tight">Import Your Data</h1>

      <p className="max-w-sm text-[13px] mt-0.5 leading-5 text-slate-500">
        Add the Excel sheet with student, course and mapping information in the desired{" "}
        <a href="" className="relative inline-flex items-center text-blue-500 underline">
          format
        </a>
        .
      </p>

      {/* File Upload Input */}
      <div
        style={{ backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgb(229 231 235)' stroke-width='3' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e\")", borderRadius: "10px" }}
        className="relative w-full h-32 max-w-sm mt-4 border-gray-200"
      >
        <div className="absolute flex flex-col items-center justify-center text-xs -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 text-slate-500">
          <input required onChange={(e) => setSelectedFile(e.target?.files[0] ?? undefined)} type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="p-0 m-0 border" />
        </div>
      </div>

      <div className="mt-4">
        <label className="text-[13px]" htmlFor="grouping">
          Grouping Factor
        </label>
        <p className="text-[13px] max-w-prose text-slate-600">
          Students with the same subjects and attributes will be grouped together in groups of <input required value={groupingFactor} onChange={(e) => setGroupingFactor(e.target.valueAsNumber)} id="grouping" name="grouping" type="number" min={1} max={10} step={1} className="px-3 py-1 mt-1 text-sm border rounded" /> students to reduce the computation time. (Default value is <pre className="inline-flex">5</pre>)
        </p>
      </div>

      <div className="flex mt-8 space-x-2">
        <Button variant="secondary" onClick={() => navigate("/")}>
          Cancel
        </Button>
        <Button loading={loading} type="submit" className="w-28">
          Validate Data
        </Button>
      </div>

      {error && <p className="text-red-600 my-2 text-[13px]">{error}</p>}
    </form>
  );
};
