import React from 'react'

export const About = () => {
  return (
    <div className="relative w-full max-w-5xl px-4 py-12 mx-auto">
        <div className="flex items-center space-x-4">
            <img src="/logo.png" alt="logo" className="w-16 h-16" />
            <h1 className="font-medium text-[13px]">LBZ Student Assignment Optimization</h1>
        </div>

        <h2>Introduction</h2>
        <p className="mt-2">This software tool was created together with the RWTH LBZ and the RWTH Learning Technologies Research Groupo (i9) as a master thesis project in 2022.</p>
        <p className="mt-2"></p>

        <p className="mt-4 text-sm">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident vel corporis nobis labore nemo esse reiciendis minus quas quod. Voluptate deleniti necessitatibus molestias impedit ullam natus eligendi sapiente. Ab, incidunt.</p>
        <h2 id="weights">Settings Weights</h2>
    </div>
  )
}
