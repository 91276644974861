import { Disclosure, Switch } from "@headlessui/react";
import clsx from "clsx";
import React, { useState } from "react";
import { Button } from "./button";
import { useNavigate } from "react-router-dom";

const round = (n, decimals) => {
  const factor = Math.pow(10, decimals);
  return Math.round(n * factor) / factor;
};

export const SettingsStep = ({ onNext, minSize, maxSize }) => {
  const navigate = useNavigate();
  
  const [name, setName] = useState("");
  const [minimizeGaps, setMinimizeGaps] = useState(false);
  // Advanced Settings
  const [dsszWeight, setDsszWeight] = useState(1);
  const [dsszLanguage, setDsszLanguage] = useState(1 - 0.8);
  const [dsszEducation, setDsszEducation] = useState(1 - 0.2);
  const [bwsWeight, setBwsWeight] = useState(1);
  const [biologyWeight, setBiologyWeight] = useState(0.3);
  const [gapWeight, setGapWeight] = useState(0.1);
  const [timeLimit, setTimeLimit] = useState(8);

  const submit = (e) => {
    e.preventDefault();

    const settings = {
      name,
      minSize: round(minSize, 1),
      maxSize: round(maxSize, 1),
      minimizeGaps,
      dsszWeight,
      dsszLanguage: round(1 - dsszLanguage, 2),
      dsszEducation: round(1 - dsszEducation, 2),
      bwsWeight: round(bwsWeight, 2),
      biologyWeight: round(biologyWeight, 2),
      gapWeight: round(gapWeight, 2),
      timeLimit: round(timeLimit, 2),
    };
    onNext(settings);
  };

  return (
    <form onSubmit={submit}>
      <h1 className="tracking-tight">Settings for the Assignment</h1>
      <p className="max-w-sm text-[13px] mt-0.5 leading-5 text-slate-600">Configure the solving process</p>

      <div className="mt-8 space-y-6 text-sm">
        <div>
          <label htmlFor="name" className="text-[13px]">
            Assignment Name
          </label>
          <p className="text-[13px] text-slate-600">Give the assignment a name to identify it later in the assignment overview.</p>
          <input value={name} onChange={(e) => setName(e.target.value)} required id="name" name="name" type="text" placeholder="Assignment WS22/23" className="px-3 py-1 mt-1 text-sm border rounded" />
        </div>

        <div>
          <label htmlFor="minimize-gaps" className="text-[13px]">
            Minimize Gaps
          </label>
          <p className="tabular-nums text-[13px] text-slate-600">
            Indicates if gaps in the student schedules should be minimized.
            <br />
            Activating this option increases computation time heavily. (Default is <pre className="inline-flex">false</pre>)
          </p>
          <Switch label="minimize-gaps" checked={minimizeGaps} onChange={setMinimizeGaps} className={clsx(minimizeGaps ? "bg-blue-600" : "bg-slate-200", "relative inline-flex mt-1.5 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2")}>
            <span className="sr-only">Use setting</span>
            <span className={clsx(minimizeGaps ? "translate-x-5" : "translate-x-0", "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out")}>
              <span className={clsx(minimizeGaps ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200", "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity")} aria-hidden="true">
                <svg className="w-3 h-3 text-slate-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
              <span className={clsx(minimizeGaps ? "opacity-100 ease-in duration-200" : "opacity-0 ease-out duration-100", "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity")} aria-hidden="true">
                <svg className="w-3 h-3 text-blue-600" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </div>

        {/* Advanced Settings */}
        <div>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="inline-flex items-center font-medium">
                  Advanced Settings
                  <svg className={`${open ? "rotate-180 transform" : ""} w-6 h-6`} fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15.25 10.75L12 14.25L8.75 10.75"></path>
                  </svg>
                </Disclosure.Button>
                <Disclosure.Panel className="p-4 mt-2 space-y-4 border-2 border-red-500 border-dashed rounded">
                  <p className="text-sm font-medium max-w-prose">To get optimal course groups, for each violation of a desired goal, e.g., a homogeneous DSSZ group, a penalty is applied. The penalties are desribed as weights here in order to be more intuitive. <a href="/about#weights" target="_blank" className="text-blue-500">Here</a> you can learn more about the weights.</p>
                  <div className="pt-2">
                    <label className="text-[13px]" htmlFor="dssz-weight">
                      DSSZ Weight
                    </label>
                    <p className="text-[13px] text-slate-600">
                      The penalty costs if a DSSZ course is not homogenous. (Default is <pre className="inline-flex">1</pre>)
                    </p>
                    <input value={dsszWeight} onChange={(e) => setDsszWeight(e.target.valueAsNumber)} required id="dssz-weight" name="dssz-weight" type="number" min={0} max={4} step={0.1} className="px-3 py-1 mt-1 text-sm border rounded" />
                  </div>
                  <div>
                    <label className="text-[13px]" htmlFor="max">
                      Language Factor / School Type Ratio
                    </label>
                    <p className="text-[13px] text-slate-600 max-w-prose">
                      How much the language reference and school type influences the homogeneity of a DSSZ courses. (Default is <pre className="inline-flex">80% - 20%</pre>)
                    </p>
                    <div className="flex items-center mt-1.5">
                      <span className="text-[13px] mr-10 relative">
                        DSSZ Language Factor <span className="absolute ml-1">{Math.round((1 - dsszLanguage) * 100)}%</span>
                      </span>
                      <input
                        id="max"
                        type="range"
                        required
                        className="ml-2"
                        min={0}
                        max={1}
                        step={0.05}
                        value={dsszLanguage}
                        onChange={(e) => {
                          setDsszLanguage(e.target.valueAsNumber);
                          setDsszEducation(1 - e.target.valueAsNumber);
                        }}
                      />
                      <span className="text-[13px] ml-2 pl-10 relative">
                        <span className="absolute left-0 mr-1">{Math.round(dsszLanguage * 100)}%</span>
                        DSSZ Education Factor
                      </span>
                    </div>
                  </div>
                  <div>
                    <label className="text-[13px]" htmlFor="bws-weight">
                      BWS Weight
                    </label>
                    <p className="text-[13px] text-slate-600">
                      The penalty costs if a BWS course is not homogenous. (Default is <pre className="inline-flex">1</pre>)
                    </p>
                    <input value={bwsWeight} onChange={(e) => setBwsWeight(e.target.valueAsNumber)} required id="bws-weight" name="bws-weight" type="number" min={0} max={4} step={0.1} className="px-3 py-1 mt-1 text-sm border rounded" />
                  </div>
                  <div>
                    <label className="text-[13px]" htmlFor="biology-weight">
                      Biology Weight
                    </label>
                    <p className="text-[13px] text-slate-600">
                      The penalty costs if a single student has no free time in front of Biology. (Default is <pre className="inline-flex">0.3</pre>)
                    </p>
                    <input value={biologyWeight} onChange={(e) => setBiologyWeight(e.target.valueAsNumber)} required id="biology-weight" name="biology-weight" type="number" min={0} max={4} step={0.1} className="px-3 py-1 mt-1 text-sm border rounded" />
                  </div>
                  <div>
                    <label className="text-[13px]" htmlFor="gap-weight">
                      Gap Weight
                    </label>
                    <p className="text-[13px] text-slate-600">
                      The penalty costs for each gap in a students schedule. (Default is <pre className="inline-flex">0.1</pre>)
                    </p>
                    <input value={gapWeight} onChange={(e) => setGapWeight(e.target.valueAsNumber)} required id="gap-weight" name="gap-weight" type="number" min={0} max={4} step={0.1} className="px-3 py-1 mt-1 text-sm border rounded" />
                  </div>
                  <div>
                    <label className="text-[13px]" htmlFor="time-limit">
                      Time Limit
                    </label>
                    <p className="text-[13px] text-slate-600">
                      The time in hours after the solving process will stop regardless if it found a solution already. (Default is <pre className="inline-flex">8</pre> hours)
                    </p>
                    <input value={timeLimit} onChange={(e) => setTimeLimit(e.target.valueAsNumber)} required id="time-limit" name="time-limit" type="number" min={0.25} max={48} step={0.25} className="px-3 py-1 mt-1 text-sm border rounded" />
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>

      <div className="flex mt-8 space-x-2">
        <Button variant="secondary" onClick={() => navigate("/")}>
          Cancel
        </Button>
        <Button type="submit">
          Start Solver
        </Button>
      </div>
    </form>
  );
};
