import clsx from "clsx";
import React, { Fragment, useState } from "react";
import { Button } from "./button";
import { Tooltip } from "./tooltip";
import { useNavigate } from "react-router-dom";

export const ValidationStep = ({ body, onNext, onPrev = () => {} }) => {
  const navigate = useNavigate();
  const [minSize, setMinSize] = useState(0.8);
  const [maxSize, setMaxSize] = useState(1.2);
  const [expandStudents, setExpandStudents] = useState(false);

  const [studentSortField, setStudentSortField] = useState();
  const [studentSortDirection, setStudentSortDirection] = useState();

  const errorMessages = body.status === "ERROR" ? body.messages || [] : [];

  return (
    <div>
      <h1 className="tracking-tight">Data Validation</h1>
      {errorMessages && errorMessages.length > 0 && (
        <>
          <p className="items-center my-2 text-sm font-medium text-red-600">An error occured while processing your data</p>
          {errorMessages.map((message) => (
            <p key={message.message} className="text-red-600 my-1 text-[13px]">
              {message.message}
            </p>
          ))}
        </>
      )}

      {body.status !== "ERROR" && (
        <>
          <div>
            {/* Group Size */}
            <div className="sticky top-0">
              <div className="bg-white pb-2 pt-6 -mx-0.5 px-0.5">
                <span className="text-[13px]">Possible Group Size</span>
                <p className="tabular-nums text-[13px] text-slate-600">
                  The group size will be between <span className="font-medium text-slate-900">{Math.round(minSize * 100)}%</span> and <span className="font-medium text-slate-900">{Math.round(maxSize * 100)}% </span>from the average. (Default is <pre className="inline-flex">80%</pre> and <pre className="inline-flex">120%</pre>)
                </p>
                {/* Sliders */}

                <div className="flex mt-2">
                  {/* Min Slider */}
                  <div className="flex items-center">
                    <label className="text-[13px]" htmlFor="min">
                      Min.
                    </label>
                    <input id="min" type="range" className="ml-2" min={0.1} max={1.0} step={0.05} value={minSize} onChange={(e) => setMinSize(e.target.valueAsNumber)} />
                  </div>
                  {/* Max Slider */}
                  <div className="flex items-center ml-10">
                    <label className="text-[13px]" htmlFor="max">
                      Max.
                    </label>
                    <input id="max" type="range" className="ml-2" min={1.0} max={2.0} step={0.05} value={maxSize} onChange={(e) => setMaxSize(e.target.valueAsNumber)} />
                  </div>
                </div>
              </div>
              <div aria-hidden="true" className="absolute z-10 w-full h-16 bg-gradient-to-b from-white to-transparent"></div>
            </div>

            {/* Courses */}
            <div className="mt-8 text-xs">
              <h2 className="text-sm">Courses</h2>

              <div className="flex flex-col mt-2">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full">
                        <thead className="bg-white">
                          <tr>
                            <th scope="col" className="py-2.5 pl-4 pr-4 text-left font-medium text-gray-900">
                              Name
                            </th>
                            <th scope="col" className="px-4 py-2.5 text-left font-medium text-gray-900">
                              Day
                            </th>
                            <th scope="col" className="px-4 py-2.5 text-left font-medium text-gray-900">
                              Time
                            </th>
                            <th scope="col" className="px-4 py-2.5 text-left font-medium text-gray-900">
                              Possible Group Size
                            </th>
                            <th scope="col" className="relative py-2.5 pl-4 pr-4">
                              <span className="sr-only">Stats</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {body.problem?.courses?.map((course) => (
                            <Fragment key={course.name}>
                              <tr className="border-t border-gray-200">
                                <th colSpan={5} scope="colgroup" className="px-4 py-2 font-semibold text-left text-gray-900 bg-gray-50">
                                  {course.name}
                                </th>
                              </tr>
                              {course.groups.map((group, groupIdx) => (
                                <tr key={group.name} className={clsx(groupIdx === 0 ? "border-gray-300" : "border-gray-200", "border-t")}>
                                  <td className="whitespace-nowrap py-2.5 pl-4 pr-4 font-medium text-gray-900">{group.name}</td>
                                  <td className="whitespace-nowrap px-4 py-2.5 text-gray-600">{group.day}</td>
                                  <td className="whitespace-nowrap px-4 py-2.5 text-gray-600">{group.time.replaceAll("_", ":")}</td>
                                  <td className="whitespace-nowrap px-4 py-2.5 text-gray-600 tabular-nums">
                                    {!group.fixed && (
                                      <>
                                        {Math.round(group.average_size * minSize)} to {Math.round(group.average_size * maxSize)} students ({Math.round(group.average_size)} average)
                                      </>
                                    )}
                                    {group.fixed && <>{Math.round(group.average_size)}</>}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-1.5 mt-px text-gray-600 inline-flex justify-center items-center">
                                    {group.fixed && (
                                      <Tooltip text="This group is fixed and can only be filled with fixed students.">
                                        <span className="sr-only">Fixed group</span>
                                        <svg aria-hidden="true" className="w-5 h-5 mt-px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M5.75 11.75C5.75 11.1977 6.19772 10.75 6.75 10.75H17.25C17.8023 10.75 18.25 11.1977 18.25 11.75V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V11.75Z" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                          <path d="M7.75008 10.5V10.3427C7.75008 8.78147 7.65615 7.04125 8.74654 5.9239C9.36837 5.2867 10.3746 4.75 12.0001 4.75C13.6256 4.75 14.6318 5.2867 15.2536 5.9239C16.344 7.04125 16.2501 8.78147 16.2501 10.3427V10.5" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                      </Tooltip>
                                    )}
                                    {group.mixed && (
                                      <Tooltip text="This group is a mixed group. It can be filled with target students.">
                                        <span className="sr-only">Mixed group</span>
                                        <svg aria-hidden="true" className="w-5 h-5 mt-px ml-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="5.25" cy="14.25" r="2.25" fill="#6B7280" />
                                          <circle cx="18.75" cy="14.25" r="2.25" fill="#6B7280" />
                                          <path d="M17.25 11.25C16.0312 9.75 15.0674 7.5 12.375 7.5C9.68261 7.5 8.71875 9.75 7.5 11.25M17.25 11.25V9M17.25 11.25H15" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                      </Tooltip>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Students */}
          <div className="mt-8 text-xs">
            <h2 className="text-sm">Students</h2>
            <div className="flex flex-col mt-2">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full">
                      <thead className="bg-white">
                        <tr>
                          <th scope="col" className="py-2.5 pl-4 pr-4 text-left font-medium text-gray-900">
                            <SortButton
                              order={studentSortField === "name" ? studentSortDirection : undefined}
                              onChange={(dir) => {
                                setStudentSortField("name");
                                setStudentSortDirection(dir);
                              }}>
                              Mat. Nr.
                            </SortButton>
                          </th>
                          <th scope="col" className="px-4 py-2.5 text-left font-medium text-gray-900">
                            Subject A
                          </th>
                          <th scope="col" className="px-4 py-2.5 text-left font-medium text-gray-900">
                            Subject B
                          </th>
                          <th scope="col" className="relative py-2.5 pl-4 pr-4 text-left font-medium text-gray-900">
                            Blocked
                          </th>
                          <th scope="col" className="px-4 py-2.5 text-left font-medium text-gray-900">
                            Fixed
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {body.problem?.students
                          ?.sort((s1, s2) => s2.fixed?.length - s1.fixed?.length)
                          ?.sort((s1, s2) => s2.blocked?.length - s1.blocked?.length)
                          ?.sort((s1, s2) => {
                            if (studentSortDirection === undefined) return 0;
                            return s1[studentSortField].localeCompare(s2[studentSortField]) * (studentSortDirection === "asc" ? 1 : -1);
                          })
                          ?.filter((_, index) => {
                            return expandStudents ? true : index < 10;
                          })
                          .map((student, studentIdx) => (
                            <tr key={student.name} className={clsx(studentIdx === 0 ? "border-slate-300" : "border-slate-200", "border-t")}>
                              <td className="whitespace-nowrap py-2.5 pl-4 pr-4 font-medium text-slate-900">{student.name}</td>
                              <td className="whitespace-nowrap py-2.5 pl-4 pr-4 text-slate-600 truncate max-w-[8rem]">{student.courses.filter((course) => course != "BWS" && course != "DSSZ")[0] || "Course not found"}</td>
                              <td className="whitespace-nowrap py-2.5 pl-4 pr-4 text-slate-600 truncate max-w-[8rem]">{student.courses.filter((course) => course != "BWS" && course != "DSSZ")[1] || "Course not found"}</td>
                              <td className="whitespace-nowrap py-2.5 pl-4 pr-4 text-slate-600">{student.blocked.join(" + ")}</td>
                              <td className="whitespace-nowrap py-2.5 pl-4 pr-4 text-slate-600">{student.fixed.join(" + ")}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    {/* Show More Button */}
                    {!expandStudents && (
                      <button onClick={() => setExpandStudents(true)} className="inline-flex px-4 py-1 mx-auto mt-4 mb-2 text-xs text-center text-blue-600 rounded">
                        Show More
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Mappings */}
          <div className="mt-8 text-xs">
            <h2 className="text-sm">Mappings</h2>
            <div className="flex flex-col mt-2">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full">
                      <thead className="bg-white">
                        <tr>
                          <th scope="col" className="py-2.5 pl-4 pr-4 text-left font-medium text-gray-900">
                            Source
                          </th>
                          <th scope="col" className="px-4 py-2.5 text-left font-medium text-gray-900">
                            Target
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {body.problem?.mappings.map((mapping, mappingIdx) => (
                          <tr key={mapping.source + mapping.target} className={clsx(mappingIdx === 0 ? "border-slate-300" : "border-slate-200", "border-t")}>
                            <td className="whitespace-nowrap py-2.5 pl-4 pr-4 text-slate-900">{mapping.source}</td>
                            <td className="whitespace-nowrap py-2.5 pl-4 pr-4 text-slate-900">{mapping.target}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* <pre>{JSON.stringify(body, null, 2)}</pre> */}
      {body.status !== "ERROR" && (
        <div className="flex mt-8 space-x-2">
          <Button variant="secondary" onClick={() => navigate("/")}>
            Cancel
          </Button>
          <Button onClick={() => onNext(minSize, maxSize)}>
            Configure Settings
          </Button>
        </div>
      )}

      {body.status === "ERROR" && (
        <Button onClick={onPrev} className="mt-8">
          Back to Upload
        </Button>
      )}
    </div>
  );
};

const SortButton = ({ onChange, order, children }) => {
  const handleClick = () => {
    let dir;
    if (order === "asc") {
      dir = "desc";
    } else if (order === "desc") {
      dir = undefined;
    } else {
      dir = "asc";
    }

    onChange(dir);
  };
  return (
    <button onClick={handleClick} className="relative inline-flex items-center pr-5">
      <span className="mr-1">{children}</span>
      {order === "asc" && (
        <svg viewBox="0 0 20 20" fill="currentColor" className="absolute top-0 right-0 w-4 h-4">
          <path
            fillRule="evenodd"
            d="M2 3.75A.75.75 0 012.75 3h11.5a.75.75 0 010 1.5H2.75A.75.75 0 012 3.75zM2 7.5a.75.75 0 01.75-.75h7.508a.75.75 0 010 1.5H2.75A.75.75 0 012 7.5zM14 7a.75.75 0 01.75.75v6.59l1.95-2.1a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 111.1-1.02l1.95 2.1V7.75A.75.75 0 0114 7zM2 11.25a.75.75 0 01.75-.75h4.562a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75z"
            clipRule="evenodd"
          />
        </svg>
      )}
      {order === "desc" && (
        <svg viewBox="0 0 20 20" fill="currentColor" className="absolute right-0 w-4 h-4">
          <path
            fillRule="evenodd"
            d="M2 3.75A.75.75 0 012.75 3h11.5a.75.75 0 010 1.5H2.75A.75.75 0 012 3.75zM2 7.5a.75.75 0 01.75-.75h6.365a.75.75 0 010 1.5H2.75A.75.75 0 012 7.5zM14 7a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02l-1.95-2.1v6.59a.75.75 0 01-1.5 0V9.66l-1.95 2.1a.75.75 0 11-1.1-1.02l3.25-3.5A.75.75 0 0114 7zM2 11.25a.75.75 0 01.75-.75H7A.75.75 0 017 12H2.75a.75.75 0 01-.75-.75z"
            clipRule="evenodd"
          />
        </svg>
      )}

      {order === undefined && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="absolute right-0 w-4 h-4 text-slate-300">
          <path fillRule="evenodd" d="M2.24 6.8a.75.75 0 001.06-.04l1.95-2.1v8.59a.75.75 0 001.5 0V4.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0L2.2 5.74a.75.75 0 00.04 1.06zm8 6.4a.75.75 0 00-.04 1.06l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75a.75.75 0 00-1.5 0v8.59l-1.95-2.1a.75.75 0 00-1.06-.04z" clipRule="evenodd" />
        </svg>
      )}
    </button>
  );
};
