import { useForm } from "react-hook-form";
import { Button } from "./components/button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const Login = () => {
  const navigate = useNavigate();
  const [ error, setError ] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const login = async (data, e) => {
    e.preventDefault();
    
    const password = data.password;
    const res = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password }),
      credentials: "include",
    });
    if (res.ok) {
      // Navigate back to home and rerender
      window.location.href = "/";
    } else {
      setError("The password was incorrect.");
    }
  };

  return (
    <form onSubmit={handleSubmit((data, e) => login(data, e))} className="flex flex-col max-w-sm p-4 mx-auto my-20 text-center bg-white rounded-lg shadow-lg shadow-slate-900/10 ring-1 ring-slate-500/5">
      <h1 className="tracking-tight">Login</h1>
      <input type="password" placeholder="Password" {...register("password", { required: true })} className="px-3 py-1.5 mt-4 text-xs border rounded" />
      {errors.password && <p className="text-xs text-red-600 text-left mt-0.5">Password is required.</p>}
      <Button type="submit" className="mt-2">
        Login
      </Button>
      {error && <p className="mt-2 text-xs text-left text-red-600">{error}</p>}
    </form>
  );
};
