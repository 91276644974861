import io from "socket.io-client";
import { useEffect, useState } from "react";

const socket = io(process.env.REACT_APP_API_URL);

export const useSolver = (onMessage) => {
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on("solve", (data) => {
      onMessage(data);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("solve");
    };
  }, []);

  return { isConnected };
};
